@import "font";
@import "custom";

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: auto !important;
	}
}

@import "../node_modules/bootstrap/scss/bootstrap.scss";
// @import "../node_modules/animate.css/animate.css";
@import "../node_modules/@fortawesome/fontawesome-free/scss/variables";

body {
	min-height: 100vh;
	min-height: -webkit-fill-available;
	background-image: linear-gradient(to right top, #d4d4c1, #c7c4b1, #bab5a2, #ada593, #a09684);
	background-attachment: fixed;
	background-clip: border-box;
	background-origin: padding-box;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: transparent;
	background-position: top right;
}

html {
	height: -webkit-fill-available;
}

main {
	display: flex;
	flex-wrap: nowrap;
}

:not(html.localhost) body .localhost-only {
	display: none !important;
	visibility: hidden !important;
}

.logo {
	max-width: 36%;
	min-width: 240px;
}
@include media-breakpoint-up(md) {
	.logo {
		min-width: 400px;
	}
}

#maincontainer {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

h2 {
	@extend .mt-3;
	margin-bottom: 0px !important;
}

// ============================================================================
// Bootstrap tweaks
//

#sidebar {
	@extend .px-3;
	@extend .bg-dark;
	width: 280px;
	z-index: 1001;

	> div {
		@extend .pt-3;
		@extend .sticky-top;
		@extend .d-flex;
		@extend .flex-column;
		@extend .flex-shrink-0;
	}

	img {
		width: 50%;
	}

	.nav-link {
		cursor: pointer;
		white-space: nowrap;
		color: $nav-pills-link-active-color;
		@include border-radius($nav-pills-border-radius);

		&:hover {
			color: $dark;
			text-decoration: underline !important;
			@include gradient-bg($nav-pills-link-active-bg);
		}

		.ff {
			text-align: center;
			display: inline-block;
			width: 24px;
			height: 24px;
			margin-right: 10px;
			pointer-events: none;
		}

		.tekst {
			pointer-events: none;
		}
	}
}

@include media-breakpoint-down(md) {
	#sidebar {
		padding-left: 1px !important;
		padding-right: 1px !important;
		width: 60px;

		.nav-link {
			.ff {
				margin-right: 0px;
			}
			.tekst {
				display: none !important;
				visibility: hidden;
			}
		}
	}
}

main > .container-fluid > article {
	min-height: 100vh;
	@extend .d-flex;
	@extend .flex-column;
	@extend .justify-content-between;

	.container {
		@extend .mt-4;
		@extend .px-2;
	}
}

.dots {
	width: 100%;
	z-index: 1000;
	height: 10vh;
	background-image: url("../images/dots2.png");
	background-repeat: repeat-x;
	background-size: contain;
	opacity: 0.5;
}

.rounded-4 {
	border-radius: 0.5rem;
}
.rounded-5 {
	border-radius: 1rem;
}
.text-shadow-1 {
	text-shadow: 0 0.125rem 0.25rem black;
}
.text-shadow-2 {
	text-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}
.text-shadow-3 {
	text-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
}

// By matching the hue and lowering the saturation/lightness, we can create an authentic shadow that doesn't have that “washed out” grey quality.
// from  hsl(220deg 100% 80%);
// to    hsl(220deg 60% 50%);
// means
// saturation -40%
// lightness -30%

@each $color, $value in $theme-colors {
	$seventy: scale-color($value, $lightness: -30%, $saturation: -40%, $alpha: -30%);
	$thirty: scale-color($value, $lightness: -30%, $saturation: -40%, $alpha: -66%);
	$twenty: scale-color($value, $lightness: -30%, $saturation: -40%, $alpha: -80%);

	.#{$color}-shadow-sm {
		box-shadow: 0.5px 1px 1px $seventy;
	}

	.#{$color}-shadow-md {
		box-shadow: 1px 2px 2px $thirty, 2px 4px 4px $thirty, 3px 6px 6px $thirty;
	}

	.#{$color}-shadow-lg {
		box-shadow: 1px 2px 2px $twenty, 2px 4px 4px $twenty, 4px 8px 8px $twenty, 8px 16px 16px $twenty,
			16px 32px 32px $twenty;
	}
}

.btn {
	text-overflow: ellipsis;
	white-space: nowrap;
}

.btn-light {
	@extend .text-dark;
	a {
		@extend .text-dark;
	}
}

.btn-success {
	@extend .text-white;
	a {
		@extend .text-white;
	}
}

.bg-primary {
	@extend .text-white;
	a {
		@extend .text-white;
	}
}

.bg-danger {
	@extend .text-white;
	a {
		@extend .text-white;
	}
}

// ============================================================================
// Ikoner
// @import "../node_modules/@fortawesome/fontawesome-free/scss/icons"

@font-face {
	font-family: "Icons";
	font-style: normal;
	font-weight: 900;
	font-display: $fa-font-display;
	src: url("#{$fa-font-path}/fa-solid-900.woff2") format("woff2");
}

.fa {
	font-family: "Icons";
	font-weight: 900;
	margin-right: 10px;
}
.ff {
	font-family: "Icons";
	font-weight: 900;
}

.#{$fa-css-prefix}-users:before {
	content: fa-content($fa-var-users);
}
.#{$fa-css-prefix}-phone:before {
	content: fa-content($fa-var-phone);
}
.#{$fa-css-prefix}-check:before {
	content: fa-content($fa-var-check);
}
.#{$fa-css-prefix}-chevron-down:before {
	content: fa-content($fa-var-chevron-down);
}
.#{$fa-css-prefix}-quote-right:before {
	content: fa-content($fa-var-quote-right);
}
.#{$fa-css-prefix}-receipt:before {
	content: fa-content($fa-var-receipt);
}
.#{$fa-css-prefix}-link:before {
	content: fa-content($fa-var-link);
}
.#{$fa-css-prefix}-clock:before {
	content: fa-content($fa-var-clock);
}
.#{$fa-css-prefix}-piggy-bank:before {
	content: fa-content($fa-var-piggy-bank);
}
.#{$fa-css-prefix}-truck:before {
	content: fa-content($fa-var-truck);
}
.#{$fa-css-prefix}-shopping-cart:before {
	content: fa-content($fa-var-shopping-cart);
}
.#{$fa-css-prefix}-arrow-left:before {
	content: fa-content($fa-var-arrow-left);
}
.#{$fa-css-prefix}-arrow-right:before {
	content: fa-content($fa-var-arrow-right);
}
.#{$fa-css-prefix}-map-marked-alt:before {
	content: fa-content($fa-var-map-marked-alt);
}
.#{$fa-css-prefix}-tractor:before {
	content: fa-content($fa-var-tractor);
}
.#{$fa-css-prefix}-exclamation-triangle:before {
	content: fa-content($fa-var-exclamation-triangle);
}
.#{$fa-css-prefix}-bug:before {
	content: fa-content($fa-var-bug);
}
.#{$fa-css-prefix}-box-open:before {
	content: fa-content($fa-var-box-open);
}
.#{$fa-css-prefix}-envelope:before {
	content: fa-content($fa-var-envelope);
}
.#{$fa-css-prefix}-plus:before {
	content: fa-content($fa-var-plus);
}
.#{$fa-css-prefix}-question-circle:before {
	content: fa-content($fa-var-question-circle);
}
.#{$fa-css-prefix}-oil-can:before {
	content: fa-content($fa-var-oil-can);
}
.#{$fa-css-prefix}-tree:before {
	content: fa-content($fa-var-tree);
}
