
@font-face {
	font-family: "Lubrinor Header";
	src: url(../fonts/header.woff2) format("woff2");
	font-display: swap;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-200.woff2) format("woff2");
	font-display: swap;
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-200it.woff2) format("woff2");
	font-display: swap;
	font-weight: 200;
	font-style: italic;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-300.woff2) format("woff2");
	font-display: swap;
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-300it.woff2) format("woff2");
	font-display: swap;
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-400.woff2) format("woff2");
	font-display: swap;
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-400it.woff2) format("woff2");
	font-display: swap;
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-700.woff2) format("woff2");
	font-display: swap;
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-700it.woff2) format("woff2");
	font-display: swap;
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-900.woff2) format("woff2");
	font-display: swap;
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: "Lubrinor Tekst";
	src: url(../fonts/text-900it.woff2) format("woff2");
	font-display: swap;
	font-weight: 900;
	font-style: italic;
}


